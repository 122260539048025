@tailwind base;
@tailwind components;
@tailwind utilities;

.wrapper {
	background-image: linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(/assets/images/banner.webp)
}

@media screen and (max-width: 640px) {
	.wrapper {
		background-image: linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(/assets/images/banner-mobile.webp)
	}
}